import React, { useState, useEffect } from 'react';
import { db, collection, getDocs } from './firebaseConfig'; // Ensure correct import
import { format } from 'date-fns'; // Optionally use date-fns for better formatting

const History = () => {
  const [snapshots, setSnapshots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [pixels, setPixels] = useState({});
  const scale = 30; // Scale for rendering
  const numCols = 40; // Number of columns in the grid
  const numRows = 500;  // Number of rows in the grid

  useEffect(() => {
    // Fetching snapshots from Firestore (from the "history" collection)
    const fetchSnapshots = async () => {
      try {
        const snapshotCollection = collection(db, 'history'); // Correct collection reference to 'history'
        const querySnapshot = await getDocs(snapshotCollection); // Use getDocs to fetch the collection
        
        if (querySnapshot.empty) {
          console.log('No snapshots found.');
          return;
        }

        const data = querySnapshot.docs.map((doc) => {
          const timestamp = doc.data().timestamp;
          const validTimestamp = timestamp && timestamp.toDate ? timestamp.toDate() : null; // Ensure timestamp is valid

          return {
            id: doc.id, // Document ID
            pixels: doc.data().pixels || {}, // Fetch the `pixels` field or default to empty object
            timestamp: validTimestamp, // Use valid timestamp if available
          };
        });

        setSnapshots(data); // Set the snapshots to state
      } catch (error) {
        console.error('Error fetching snapshots:', error);
      }
    };

    fetchSnapshots();
  }, []); // Run once on component mount

  useEffect(() => {
    // Load pixels for the selected snapshot (date)
    if (selectedDate) {
      const snapshot = snapshots.find((s) => s.id === selectedDate);
      setPixels(snapshot ? snapshot.pixels : {});
    }
  }, [selectedDate, snapshots]);

  // Function to handle exit (clear the selected date and hide canvas)
  const handleExit = () => {
    setSelectedDate(null); // Clear selected snapshot
    setPixels({}); // Clear pixels data
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1>Canvas History</h1>
{/* Exit Button to hide canvas */}
{selectedDate && (
        <button 
          onClick={handleExit} 
          style={{
            marginTop: '20px',
            padding: '10px 20px',
            fontSize: '16px',
            cursor: 'pointer',
            backgroundColor: '#f44336', // Red color for exit button
            color: 'white',
            border: 'none',
            borderRadius: '5px',
          }}
        >
          Exit Snapshot
        </button>
      )}
      {/* Dropdown to select a date */}
      <select onChange={(e) => setSelectedDate(e.target.value)} value={selectedDate || ''}>
        <option value="" disabled>Select a snapshot</option>
        {snapshots.map((snapshot) => (
          <option key={snapshot.id} value={snapshot.id}>
            {snapshot.timestamp ? format(snapshot.timestamp, 'yyyy-MM-dd HH:mm:ss') : 'Unknown time'} {/* Format the timestamp or fallback */}
          </option>
        ))}
      </select>

      {/* Display canvas for the selected date */}
      {selectedDate && (
        <div
          style={{
            margin: '20px auto',
            border: '1px solid black',
            width: '615px', // Ensure this matches the canvas width
            height: '400px', // Ensure this matches the canvas height
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <canvas
            width={numCols * scale}  // Canvas width based on scale and number of columns
            height={numRows * scale} // Canvas height based on scale and number of rows
            style={{
              imageRendering: 'pixelated', // Keep pixelated rendering for the canvas
            }}
            ref={(canvas) => {
              if (canvas) {
                const context = canvas.getContext('2d');
                context.clearRect(0, 0, canvas.width, canvas.height);

                // Draw pixels from the selected snapshot
                Object.entries(pixels).forEach(([key, color]) => {
                  const [x, y] = key.split(',').map(Number);

                  // Only draw pixels within the valid canvas area (100 x 40 grid)
                  if (x >= 0 && x < numCols && y >= 0 && y < numRows) {
                    context.fillStyle = color;
                    context.fillRect(x * scale, y * scale, scale, scale);
                  }
                });

                // Draw grid
                context.strokeStyle = '#ccc';
                context.lineWidth = 0.5;
                for (let x = 0; x <= numCols; x++) {
                  for (let y = 0; y <= numRows; y++) {
                    context.strokeRect(x * scale, y * scale, scale, scale);
                  }
                }
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default History;
