import React, { useState, useEffect, useContext } from 'react';
import { firestore } from './firebaseConfig';
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  serverTimestamp,
  doc,
  getDoc,
} from 'firebase/firestore';
import { AuthContext } from './AuthContext';

function Chatbox() {
  const { loggedInUser } = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch the rank of a user
  const fetchUserRank = async (username) => {
    try {
      const userRef = doc(firestore, 'users', username);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        return userDoc.data().rank || 'User'; // Return rank or default to 'User'
      }
    } catch (error) {
      console.error('Error fetching user rank:', error);
    }
    return 'User'; // Default to 'User' in case of an error
  };

  // Fetch and subscribe to messages with rank
  const fetchMessages = () => {
    const messagesRef = collection(firestore, 'messages');
    const q = query(messagesRef, orderBy('createdAt', 'asc'));

    // Real-time listener for messages
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const messagesData = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const messageData = { id: doc.id, ...doc.data() };
          const rank = await fetchUserRank(messageData.username); // Fetch rank for each message's sender
          return { ...messageData, rank };
        })
      );
      setMessages(messagesData);
    });

    return unsubscribe;
  };

  // Handle sending a new message
  const handleSendMessage = async () => {
    if (!newMessage.trim()) {
      setErrorMessage('Please enter a message.');
      return;
    }

    try {
      const messagesRef = collection(firestore, 'messages');
      await addDoc(messagesRef, {
        content: newMessage,
        username: loggedInUser ? loggedInUser.username : 'Guest',
        createdAt: serverTimestamp(),
      });

      setNewMessage('');
      setErrorMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      setErrorMessage('Error sending message. Please try again.');
    }
  };

  // Initialize message listener on component mount
  useEffect(() => {
    const unsubscribe = fetchMessages();
    return () => unsubscribe(); // Cleanup on component unmount
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '400px',
        height: '600px',
        margin: 'auto',
        border: '1px solid #555',
        backgroundColor: '#000',
        color: '#fff',
        fontFamily: 'monospace',
        borderRadius: '5px',
      }}
    >
      <div
        style={{
          flexGrow: 1,
          padding: '10px',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse', // Reverse the flex direction for scrolling from the top
          borderBottom: '1px solid #333',
        }}
      >
        <div>
  {messages.map((msg) => (
    <div
      key={msg.id}
      style={{
        marginBottom: '5px',
        wordBreak: 'break-word',
      }}
    >
      <span
        style={{
          color: '#2889e2',
        }}
      >
        [{msg.createdAt
          ? new Date(msg.createdAt.seconds * 1000).toLocaleString()
          : '----'}]:
      </span>{' '}
      <span
        style={{
          color:
            msg.rank === 'Admin'
              ? '#e22838' // Admin usernames appear in red
              : msg.username === (loggedInUser?.username || 'Guest')
              ? '#fff' // Highlight logged-in user's username in white
              : '#6cf', // Default username color
        }}
      >
        ({msg.rank}) {msg.username}:{' '}
      </span>
      <span>{msg.content}</span>
    </div>
  ))}
</div>

      </div>
      <div
        style={{
          padding: '5px',
          borderTop: '1px solid #333',
          backgroundColor: '#111',
        }}
      >
        {errorMessage && (
          <p style={{ color: 'red', marginBottom: '5px' }}>{errorMessage}</p>
        )}
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Chat here"
          style={{
            width: '97%',
            padding: '5px',
            backgroundColor: '#222',
            border: '1px solid #555',
            color: '#fff',
            borderRadius: '3px',
            fontFamily: 'monospace',
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5px',
          }}
        >
          <button
            onClick={handleSendMessage}
            style={{
              width: '90%',
              padding: '5px',
              backgroundColor: '#444',
              color: '#fff',
              border: 'none',
              borderRadius: '3px',
              cursor: 'pointer',
            }}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
}

export default Chatbox;
