// src/firebase-config.js
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from 'firebase/database';  // For Realtime Database
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, doc, setDoc, deleteDoc, onSnapshot, getDocs, addDoc } from "firebase/firestore"; // Import getDocs and addDoc

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAmBkIvanX3xcEjS3wfv8lB8LWKsxdeQRM",
  authDomain: "solagrace-defcf.firebaseapp.com",
  projectId: "solagrace-defcf",
  storageBucket: "solagrace-defcf.firebasestorage.app",
  messagingSenderId: "688560637655",
  appId: "1:688560637655:web:a468a769b53ad153fed292",
  measurementId: "G-8YHV1X9FPX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Export Firestore methods and the db instance
export { db, collection, doc, setDoc, deleteDoc, onSnapshot, getDocs, addDoc };  // Add getDocs and addDoc to the exports
export const firestore = getFirestore(app);  // Firestore