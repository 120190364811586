import React, { useState, useContext } from 'react';
import { firestore } from './firebaseConfig';
import { collection, query, where, getDocs, setDoc, doc } from 'firebase/firestore';
import bcrypt from 'bcryptjs';
import { AuthContext } from './AuthContext';

const AuthForm = () => {
  const { loggedInUser, login, logout } = useContext(AuthContext);
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');

  const handleSignUp = async () => {
    const usernameRegex = /^[a-zA-Z0-9_]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const offensiveWords = ['shit', 'fuck', 'dick', 'nigger', 'cunt', 'bitch'];

    // Username validation
    if (offensiveWords.some(word => username.toLowerCase().includes(word))) {
      setMessage('Username contains offensive language. Please choose a different one.');
      return;
    }

    if (!usernameRegex.test(username)) {
      setMessage('Username can only contain letters, numbers, and underscores.');
      return;
    }

    // Email validation
    if (!emailRegex.test(email)) {
      setMessage('Please enter a valid email address.');
      return;
    }

    try {
      // Check if username already exists
      const usernameQuery = query(collection(firestore, 'users'), where('username', '==', username));
      const usernameSnapshot = await getDocs(usernameQuery);
      if (!usernameSnapshot.empty) {
        setMessage('Username already exists. Please choose a different one.');
        return;
      }

      // Check if email already exists
      const emailQuery = query(collection(firestore, 'users'), where('email', '==', email));
      const emailSnapshot = await getDocs(emailQuery);
      if (!emailSnapshot.empty) {
        setMessage('Email is already registered. Please use a different email.');
        return;
      }

      // Hash the password and save the new user
      const hashedPassword = await bcrypt.hash(password, 10);
      const docRef = doc(firestore, 'users', username);
      await setDoc(docRef, {
        username,
        email,
        password: hashedPassword,
        buttonScore: 0,
        rank: 'User',
      });

      setMessage('Sign-up successful! You can now log in.');
      setIsLogin(true);
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  const handleLogin = async () => {
    try {
      // Search for the user by email
      const q = query(collection(firestore, 'users'), where('email', '==', email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setMessage('User not found.');
        return;
      }

      const userDoc = querySnapshot.docs[0];
      const userData = userDoc.data();

      // Compare the entered password with the hashed password
      const isPasswordValid = await bcrypt.compare(password, userData.password);

      if (isPasswordValid) {
        login({
          username: userData.username,
          email: userData.email,
          rank: userData.rank,
        });
        setMessage(`Welcome back, ${userData.username}!`);
      } else {
        setMessage('Invalid password.');
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  const handleLogout = () => {
    logout();
    setMessage('Logged out successfully.');
  };

  return (
    <div style={{ padding: '20px', maxWidth: '400px', margin: 'auto' }}>
      <h1>{isLogin ? 'Login' : 'Sign Up'}</h1>

      {loggedInUser ? (
        <div>
          <p>
            <strong>Welcome, {loggedInUser.username}!</strong>
          </p>
          <button onClick={handleLogout} style={{ width: '100%', marginBottom: '10px' }}>
            Log Out
          </button>
        </div>
      ) : (
        <div>
          {!isLogin && (
            <div>
              <label>Username:</label>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                style={{ display: 'block', marginBottom: '10px', width: '100%' }}
              />
            </div>
          )}

          <div>
            <label>Email:</label>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ display: 'block', marginBottom: '10px', width: '100%' }}
            />
          </div>

          <div>
            <label>Password:</label>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ display: 'block', marginBottom: '20px', width: '100%' }}
            />
          </div>

          {isLogin ? (
            <button onClick={handleLogin} style={{ marginBottom: '10px', width: '100%' }}>
              Log In
            </button>
          ) : (
            <button onClick={handleSignUp} style={{ marginBottom: '10px', width: '100%' }}>
              Sign Up
            </button>
          )}

          <button
            onClick={() => setIsLogin(!isLogin)}
            style={{ display: 'block', marginTop: '10px', width: '100%', backgroundColor: 'gold', color: 'black' }}
          >
            {isLogin ? "Don't have an account? Sign Up" : 'Already have an account? Log In'}
          </button>
        </div>
      )}

      {message && <p style={{ color: isLogin ? 'green' : 'blue', marginTop: '20px' }}>{message}</p>}
    </div>
  );
};

export default AuthForm;
