import React, { useEffect, useState } from 'react';
import { db, collection, onSnapshot } from './firebaseConfig';

const Leaderboard = () => {
  const [colorCounts, setColorCounts] = useState({});

  // Load initial pixels and compute color counts
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "pixels"), (snapshot) => {
      const grid = {};
      snapshot.forEach((doc) => {
        grid[doc.id] = doc.data().color;
      });

      const counts = {};
      Object.values(grid).forEach((color) => {
        if (color !== '#000000') { // Ignore default color (black)
          counts[color] = counts[color] ? counts[color] + 1 : 1;
        }
      });

      setColorCounts(counts);
    });

    return () => unsubscribe();
  }, []);

  const sortedColors = Object.entries(colorCounts)
    .sort(([, countA], [, countB]) => countB - countA) // Sort by count descending
    .slice(0, 10); // Limit to top 10 colors

  // Helper function to calculate brightness
  const getBrightness = (color) => {
    color = color.replace('#', ''); // Remove '#' if present
    const r = parseInt(color.slice(0, 2), 16); // Red value
    const g = parseInt(color.slice(2, 4), 16); // Green value
    const b = parseInt(color.slice(4, 6), 16); // Blue value

    // Calculate brightness using the luminance formula
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  };

  // Determine the best text color (black or white) based on brightness
  const getTextColor = (backgroundColor) => {
    const brightness = getBrightness(backgroundColor);
    return brightness > 128 ? 'black' : 'white'; // If bright, use black text; otherwise, white
  };

  return (
    <div style={{ marginTop: '20px', textAlign: 'center' }}>
      <h3>Leaderboard</h3>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {sortedColors.map(([color, count], index) => {
          const textColor = getTextColor(color); // Get appropriate text color
          return (
            <li key={index} style={{ marginBottom: '5px' }}>
              <span style={{ marginRight: '8px' }}>#{index + 1}</span>
              <span
                style={{
                  backgroundColor: color,
                  padding: '3px 8px',
                  borderRadius: '5px',
                  marginRight: '8px',
                  color: textColor, // Apply dynamic text color
                }}
              >
                {color}
              </span>
              <span>{count} pixels</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Leaderboard;
