import React, { createContext, useState, useEffect } from 'react';
import { firestore } from './firebaseConfig';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [buttonScore, setButtonScore] = useState(0);
  const [rank, setRank] = useState('User');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userData = localStorage.getItem('loggedInUser');
    if (userData) {
      const user = JSON.parse(userData);
      setLoggedInUser(user);
      fetchUserData(user.username);  // Fetch user data when logged in
    } else {
      setLoading(false);
    }
  }, []);

  const fetchUserData = async (username) => {
    try {
      const userRef = doc(firestore, 'users', username);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const data = userDoc.data();
        setButtonScore(data.buttonScore || 0);
        setRank(data.rank || 'User');
        setLoggedInUser((prev) => ({ ...prev, rank: data.rank || 'User' })); // Sync local user state
      } else {
        await setDoc(userRef, { buttonScore: 0, rank: 'User' });
        setButtonScore(0);
        setRank('User');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setLoading(false);
    }
  };

  const updateButtonScore = async () => {
    if (!loggedInUser?.username) {
      console.error('User is not logged in.');
      return;
    }

    try {
      const userRef = doc(firestore, 'users', loggedInUser.username);
      const newButtonScore = buttonScore + 1;

      await updateDoc(userRef, { buttonScore: newButtonScore });
      setButtonScore(newButtonScore);
    } catch (error) {
      console.error('Error updating button score:', error);
    }
  };

  const updateRank = async (newRank) => {
    if (!loggedInUser?.username) {
      console.error('User is not logged in.');
      return;
    }

    try {
      const userRef = doc(firestore, 'users', loggedInUser.username);
      await updateDoc(userRef, { rank: newRank });
      setRank(newRank);
      setLoggedInUser((prev) => ({ ...prev, rank: newRank })); // Sync rank with loggedInUser
    } catch (error) {
      console.error('Error updating rank:', error);
    }
  };

  const login = (user) => {
    setLoggedInUser({ ...user, rank });
    localStorage.setItem('loggedInUser', JSON.stringify({ ...user, rank }));
    fetchUserData(user.username);
  };

  const logout = () => {
    setLoggedInUser(null);
    setButtonScore(0);
    setRank('User');
    localStorage.removeItem('loggedInUser');
  };

  return (
    <AuthContext.Provider
      value={{
        loggedInUser,
        login,
        logout,
        buttonScore,
        updateRank,
        updateButtonScore,
        rank,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
