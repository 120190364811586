import React, { useRef, useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { db, collection, doc, setDoc, deleteDoc, onSnapshot, addDoc } from './firebaseConfig'; // Import addDoc
import { AuthContext } from './AuthContext';

const Canvas = () => {
  const { loggedInUser } = useContext(AuthContext);
  const canvasRef = useRef(null);
  const [pixels, setPixels] = useState({});
  const [selectedColor, setSelectedColor] = useState('#000000');
  const [tool, setTool] = useState('brush');
  const [isAdmin, setIsAdmin] = useState(false);
  const [lastActionTime, setLastActionTime] = useState(0);  // Track last action time
  const [remainingTime, setRemainingTime] = useState(0);  // Track remaining wait time
  const location = useLocation();
  const scale = 30;
  const numRows = 500;
  const numCols = 40;
  const [hoverPixel, setHoverPixel] = useState(null); // Tracks the currently hovered pixel

  const collectionName = location.pathname === '/' ? 'pixels' : `pixels_${location.pathname.replace(/\//g, '_')}`;

  // Check if the logged-in user is an Admin
  useEffect(() => {
    if (loggedInUser && loggedInUser.rank === 'Admin') {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [loggedInUser]);

  // Load initial pixels from Firestore
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, collectionName), (snapshot) => {
      const updatedPixels = {};
      snapshot.forEach((doc) => {
        updatedPixels[doc.id] = doc.data().color;
      });
      setPixels(updatedPixels);
    });

    return () => unsubscribe();
  }, [collectionName]);

  // Load last action time from localStorage to persist across refreshes
  useEffect(() => {
    const savedLastActionTime = parseInt(localStorage.getItem('lastActionTime'), 10);
    if (savedLastActionTime) {
      setLastActionTime(savedLastActionTime);
    }
  }, []);

  // Set the countdown timer
  useEffect(() => {
    if (lastActionTime > 0) {
      const timer = setInterval(() => {
        const currentTime = Date.now();
        const timeLeft = Math.max(0, 10000 - (currentTime - lastActionTime));
        setRemainingTime(timeLeft);

        if (timeLeft === 0) {
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [lastActionTime]);

  // Draw or delete pixel function with time restriction
  const drawPixel = async (x, y, color) => {
    const currentTime = Date.now();
    
    // Check if the user is not an admin and the 10-second wait time is still in effect
    if (!isAdmin && currentTime - lastActionTime < 10000) {
      alert('You must wait 10 seconds between placing pixels.');
      return; // Prevent placing the pixel if 10 seconds haven't passed for non-admins
    }
  
    // Update last action time for non-admins
    if (!isAdmin) {
      setLastActionTime(currentTime);
      localStorage.setItem('lastActionTime', currentTime);
    }
  
    if (color) {
      await setDoc(doc(db, collectionName, `${x},${y}`), { color }, { merge: true });
    } else {
      await deleteDoc(doc(db, collectionName, `${x},${y}`));
    }
  };
  // Handle canvas click for both brush, color picker, and eraser tools
  const handleCanvasClick = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    // Adjusting click coordinates to account for the canvas scale
    const x = Math.floor((e.clientX - rect.left) / scale);
    const y = Math.floor((e.clientY - rect.top) / scale);

    if (tool === 'brush') {
      drawPixel(x, y, selectedColor); // Draw with the selected color (brush tool)
    } else if (tool === 'picker') {
      const pixelColor = pixels[`${x},${y}`] || '#FFFFFF'; // Default to white if no color is present
      setSelectedColor(pixelColor); // Pick the color from the pixel (picker tool)
    } else if (tool === 'eraser') {
      drawPixel(x, y, null); // Delete the pixel (eraser tool)
    }
  };

  // Handle mouse move event to highlight the pixel under the cursor
  const handleMouseMove = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    // Adjusting mouse coordinates to account for the canvas scale
    const x = Math.floor((e.clientX - rect.left) / scale);
    const y = Math.floor((e.clientY - rect.top) / scale);

    setHoverPixel({ x, y });
  };

  // Reset hover pixel when mouse leaves the canvas
  const handleMouseLeave = () => {
    setHoverPixel(null);
  };

  // Draw 4x4 block for Admin tool (brush or eraser)
  const handleAdminToolClick = (e) => {
    if (!isAdmin) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    // Adjusting click coordinates to account for the canvas scale
    const x = Math.floor((e.clientX - rect.left) / scale);
    const y = Math.floor((e.clientY - rect.top) / scale);

    // Loop to draw or erase 4x4 block of pixels at clicked position
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 4; j++) {
        if (tool === 'eraser') {
          drawPixel(x + i, y + j, null); // Erase (delete) the pixels
        } else {
          drawPixel(x + i, y + j, selectedColor); // Draw with the selected color
        }
      }
    }
  };

  // Redraw the canvas when pixels or hover pixel change
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // Clear and redraw the canvas
    context.clearRect(0, 0, canvas.width, canvas.height);

    // Draw the pixels
    Object.entries(pixels).forEach(([key, color]) => {
      const [x, y] = key.split(',').map(Number);
      context.fillStyle = color;
      context.fillRect(x * scale, y * scale, scale, scale);
    });

    // Draw the grid lines
    context.strokeStyle = '#ccc'; // Light gray for grid lines
    context.lineWidth = 0.5;
    for (let x = 0; x <= numCols; x++) {
      for (let y = 0; y <= numRows; y++) {
        context.strokeRect(x * scale, y * scale, scale, scale);
      }
    }

    // Highlight the pixel being hovered over
    if (hoverPixel) {
      const { x, y } = hoverPixel;
      context.fillStyle = 'rgba(255, 255, 255, 0.5)';
      context.fillRect(x * scale, y * scale, scale, scale);
    }
  }, [pixels, hoverPixel]);

  // Save current pixels to the history collection
  const saveToHistory = async () => {
    if (!isAdmin) return;
    const historyEntry = {
      pixels,
      timestamp: new Date(),
    };

    try {
      await addDoc(collection(db, 'history'), historyEntry); // Save history entry to Firestore
      alert('State saved to history!');
    } catch (error) {
      console.error('Error saving to history:', error);
      alert('Failed to save state to history.');
    }
  };
  const getCanvasClass = () => {
    switch (tool) {
      case 'brush':
        return 'canvas canvas-brush';
      case 'picker':
        return 'canvas canvas-picker';
      case 'eraser':
        return 'canvas canvas-eraser';
      case 'admin':
        return 'canvas canvas-admin';
      default:
        return 'canvas';
    }
  };
  
  return (
    <div style={{ width: '600px', margin: '0 auto', paddingTop: '20px' }}>
      {/* Color picker outside of canvas */}
      <div style={{ marginBottom: '10px', textAlign: 'left' }}>
        <label style={{ marginRight: '10px' }}>Choose Color: </label>
        <input
          type="color"
          value={selectedColor}
          onChange={(e) => setSelectedColor(e.target.value)}
        />
      </div>

      {/* Tool selector (brush, color picker, eraser, or admin 4x4 tool) */}
      <div style={{ marginBottom: '10px', textAlign: 'left' }}>
        <button
          onClick={() => setTool('brush')}
          style={{
            padding: '5px 15px',
            backgroundColor: '#555',
            borderColor: tool === 'brush' ? 'green' : '',
            border: '2px solid black',
          }}
        >
          Brush Tool
        </button>
        <button
          onClick={() => setTool('picker')}
          style={{
            padding: '5px 15px',
            backgroundColor: '#555',
            borderColor: tool === 'picker' ? 'green' : '',
            border: '2px solid black',
            marginLeft: '10px',
          }}
        >
          Color Picker Tool
        </button>
        {isAdmin && (
          <button
            onClick={() => setTool('eraser')}
            style={{
              padding: '5px 15px',
              backgroundColor: '#ff6666',
              borderColor: tool === 'eraser' ? 'green' : '',
              border: '2px solid black',
              marginLeft: '10px',
            }}
          >
            Eraser Tool
          </button>
        )}
        {isAdmin && (
          <button
            onClick={() => setTool('admin')}
            style={{
              padding: '5px 15px',
              backgroundColor: '#ff3333',
              borderColor: tool === 'admin' ? 'green' : '',
              border: '2px solid black',
              marginLeft: '10px',
            }}
          >
            4x4 Paint Tool
          </button>
        )}
      </div>

      {/* Countdown timer */}
      <div style={{ marginBottom: '10px', textAlign: 'left' }}>
        {remainingTime > 0 ? (
          <p>You must wait {Math.ceil(remainingTime / 1000)} seconds before placing another pixel.</p>
        ) : (
          <p>You can place another pixel now!</p>
        )}
      </div>

      {/* Button to save to history */}
      {isAdmin && (
        <div style={{ marginBottom: '10px' }}>
          <button
            onClick={saveToHistory}
            style={{
              padding: '5px 15px',
              backgroundColor: '#4CAF50',
              borderColor: '#388E3C',
              color: 'white',
              border: '2px solid black',
            }}
          >
            Save to History
          </button>
        </div>
      )}

      {/* Canvas container with scrollable area */}
      <div
        style={{
          width: '615px',
          height: '400px',
          overflowY: 'auto',
          overflowX: 'hidden',
          backgroundColor: 'white',
        }}
      >
       <canvas
  ref={canvasRef}
  width={numCols * scale}
  height={numRows * scale}
  onClick={tool === 'admin' || tool === 'eraser' ? handleAdminToolClick : handleCanvasClick}
  onMouseMove={handleMouseMove}
  onMouseLeave={handleMouseLeave}
  className={getCanvasClass()} // Dynamically set the class
  style={{
    border: '1px solid black',
    imageRendering: 'pixelated',
  }}
/>
      </div>
    </div>
  );
};

export default Canvas;
