import React from 'react';
import { AuthProvider } from './AuthContext';
import Canvas from './Canvas';
import AuthForm from './AuthForm';
import Leaderboard from './Leaderboard';
import ChatBox from './ChatBox';
import { useLocation } from 'react-router-dom'; // Import useLocation
import History from './History';

const App = () => {
  const location = useLocation(); // Get current URL path

  return (
    <AuthProvider>
      <h1>
        <strong>N</strong>ot <strong>E</strong>nough <strong>P</strong>ixels
      </h1>
      <p>EARLY ON IN DEVELOPMENT</p>
      <div className="App" style={{ display: 'flex' }}>
        
        {/* Conditionally render Leaderboard only if at root directory */}
        {location.pathname === '/' && <Leaderboard />}
        
        <div style={{ marginLeft: '50px' }}>
          <Canvas />
          <History/>
        </div>
        
        <div style={{ marginLeft: '50px' }}>
          <ChatBox />
        </div>
      </div>

      <div style={{ flex: 1, padding: '20px' }}>
        <AuthForm />
      </div>
    </AuthProvider>
  );
};

export default App;
